.infos {
    background: #051916;
    height: auto;
    width: 100%;
    position: relative;
    height: 70vh;
    padding: 5rem 20dvw;
    box-sizing: border-box;
}

.phone {
    display: none;
}

.texture {
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    object-fit: cover;

    opacity: .2;

    pointer-events: none;
}

.infos-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    height: 100%;

    z-index: 10;
    position: relative;
}

.desc {
    font-size: 20px;
    width: 50%;
    margin-bottom: 0;
    color: var(--white);
    font-weight: 500;
    text-align: justify;
    direction:rtl;
}

.tel {
    font-size: 5vw;
    color: var(--white);
    font-weight: 700;
    text-align: left;
    width: min-content;
    margin: 0;
}

.row {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    width: 100%;
    gap: 2rem;
}

.row > div {
    flex-grow: 1;
    height: 100%;

    opacity: .2;
}

.top-detail {
    border-top: 3px solid var(--white);
    border-left: 3px solid var(--white);
}

.bottom-detail {
    border-bottom: 3px solid var(--white);
    border-right: 3px solid var(--white);
}

@media (max-width: 768px) {
    .designer {
        bottom: auto;
        letter-spacing: 0rem;
        font-size: 280px;
    }
    .infos {
        padding: 2rem;
        height: auto;
    }
    .desc {
        font-size: 16px;
        text-align: justify;
        direction:ltr;
        font-weight: 500;
        margin-left: 0;
        width: 100%;
    }
    .tel {
        font-size: 28px;
        font-weight: 900;
        text-align: center;
        width: 100%;
        margin: 0;
        margin-top: 5vh;
        line-height: 5rem;
    }
    .phone {
        display: block
    }
    .desktop {
        display: none;
    }
    .row > div {
        display: none;
    }
}