.modal-container {
    width: 100dvw;
    height: 100dvh;

    position: fixed;

    top: 0;
    left: 0;

    background-color: rgba(0, 0 , 0, .85);

    z-index: 30;
}

.modal-img {
    height: 100%;
    width: 100%;
    object-fit: contain;

    text-align: center;
}

.modal-close-btn {
    position: fixed;

    top: 3em;
    right: 3em;

    padding: .5em;

    cursor: pointer;

    background-color: #FFFAF0;
    border-radius: 999px;

    display: flex;

    height: 30px;
    width: 30px;

    box-sizing: content-box;
}

@media (max-width: 768px) {
    .modal-close-btn {
        top: 1.5em;
        right: 1.5em;
    }
}