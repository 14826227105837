.header {
    position: relative;
    height: 80vh;
    width: 100%;
    padding: 5rem;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 1) 0px 30px 60px -12px inset, rgba(0, 0, 0, .3) 0px 18px 36px -18px inset;
}

.header-bg {
    position: absolute;
    height: 104%;
    width: 104%;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    object-fit: cover;

    z-index: -1;

    background-color: #051916;
    text-align: center;
}

.instagram-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.instagram {
    background: rgba(255, 255, 255, 0.2);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    padding: .5rem;
    cursor: pointer;
}

.title-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90%
}

.name {
    color: var(--white);
    font-weight: 700;
    font-size: 5rem;
    text-align: center;
    margin: 0;

    animation: 2.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 fadeIn;
}

.subtitle-txt {
    width: 100%;
    margin: 0;

    text-align: center;

    color: #F1875E;
    font-weight: 700;
    font-size: 1.5rem;
    height: 3rem;
    border-radius: 2rem;
}

@keyframes fadeIn {
    0% {
        transform: scale(0.8);
        filter: blur(5px);
        opacity: 0;
    }
    100% {
        filter: blur(0);
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .subtitle-txt {
        font-size: 1rem;
    }
    .name {
        font-size: 2.3rem;
    }
    .header {
        padding: 2rem;
        height: 60vh;
    }
}