.recent-work 
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;

  width: min-content;
  padding-top: 5vh;
  padding-left: 5vw;
}

.recent-work > h2
{
  font-weight: 700;
  font-size: 3rem;
  margin: 0;
}

.detail {
  width: 10rem;
  height: 10rem;
  position: absolute;
  bottom: -5rem;
  right: -5rem;

  z-index: -1;
}

@media (max-width: 768px) {
    .recent-work > h2
    {
      font-weight: 700;
      font-size: 10vw;
      margin: 0;
    }
    .recent-work 
    {
      padding-top: 5vh;
      padding-left: 5vw;
      margin-bottom: 5vh;
    }
    .detail {
      width: 7rem;
      height: 7rem;
      bottom: -2.5rem;
      right: -3rem;
    }
}
