.swiper {
    width: 74vw;
    height: 70vh;
    margin-top: 2vh;
    margin-bottom: 7vh;

    position: relative;

    z-index: 20!important;
}

.phone {
    display: none;
}

.swiper-slide {
    color: black;
    align-items: center;
    justify-content: center;
    display: flex;
}

.swiper-slide-active {
    transform: translateZ(1px) scale(1.3)!important;
    transition: all .3s ease-in;
    -webkit-animate-timing-function: ease-in;
    z-index: 2;
}

.swiper-slide-active .carousel-image-btn {
    pointer-events: auto;
    cursor: pointer;
}

.carousel-image-btn {
    width: 100%;

    background: none;
    border: none;

    pointer-events: none;
}

.carousel-image {
    height: 100%;
    width: 100%;

    transition: all .3s ease-in;
    -webkit-animate-timing-function: ease-in;
    border-radius: 1.3rem;

    object-fit: cover;

    text-align: center;
    line-height: 3rem;
}

.swiper-button-next,
.swiper-button-prev {
    padding: .5rem;
    position: absolute;

    /* Centering top */
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;

    z-index: 2;

    background-color: rgba(241, 135, 94, .25);
    border-radius: 999px;
}

.swiper-button-next {
    right: 10%;
}

.swiper-button-prev {
    left: 10%;
}

.column-center {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.void {
    padding: 8%;
    gap: 2.5rem;
    font-size: 2rem;
}

.void-txt {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .swiper {
        width: 95vw;
        height: 50vh;
        margin-top: 2vh;
        margin-bottom: 7vh;
    }
    .swiper-slide .image {
        margin: 1rem 0;
        box-sizing: border-box;
    }
    .phone {
        display: block;
    }
    .desktop {
        display: none;
    }
    .swiper-slide-active {
        transform: scale(1)!important;
        transition: all .3s ease-in;
        -webkit-animate-timing-function: ease-in;
        z-index: 2;
    }

    .swiper-button-next,
    .swiper-button-prev {
        padding: .2rem;
        background-color: #fffaf08e;
        position: absolute;

        /* Centering top */
        top: 80%;

        cursor: pointer;

        z-index: 2;

        border-radius: 1rem;
    }

    .swiper-button-next {
        right: 10%;
    }

    .swiper-button-prev {
        left: 10%;
    }
}